exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-about-js": () => import("./../../../src/pages/company/about.js" /* webpackChunkName: "component---src-pages-company-about-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-company-partnership-js": () => import("./../../../src/pages/company/partnership.js" /* webpackChunkName: "component---src-pages-company-partnership-js" */),
  "component---src-pages-company-shareholders-js": () => import("./../../../src/pages/company/shareholders.js" /* webpackChunkName: "component---src-pages-company-shareholders-js" */),
  "component---src-pages-company-team-js": () => import("./../../../src/pages/company/team.js" /* webpackChunkName: "component---src-pages-company-team-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-en-company-about-js": () => import("./../../../src/pages/en/company/about.js" /* webpackChunkName: "component---src-pages-en-company-about-js" */),
  "component---src-pages-en-company-index-js": () => import("./../../../src/pages/en/company/index.js" /* webpackChunkName: "component---src-pages-en-company-index-js" */),
  "component---src-pages-en-company-partnership-js": () => import("./../../../src/pages/en/company/partnership.js" /* webpackChunkName: "component---src-pages-en-company-partnership-js" */),
  "component---src-pages-en-company-shareholders-js": () => import("./../../../src/pages/en/company/shareholders.js" /* webpackChunkName: "component---src-pages-en-company-shareholders-js" */),
  "component---src-pages-en-company-team-js": () => import("./../../../src/pages/en/company/team.js" /* webpackChunkName: "component---src-pages-en-company-team-js" */),
  "component---src-pages-en-contacts-js": () => import("./../../../src/pages/en/contacts.js" /* webpackChunkName: "component---src-pages-en-contacts-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-news-index-js": () => import("./../../../src/pages/en/news/index.js" /* webpackChunkName: "component---src-pages-en-news-index-js" */),
  "component---src-pages-en-policy-js": () => import("./../../../src/pages/en/policy.js" /* webpackChunkName: "component---src-pages-en-policy-js" */),
  "component---src-pages-en-projects-index-js": () => import("./../../../src/pages/en/projects/index.js" /* webpackChunkName: "component---src-pages-en-projects-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-templates-news-detail-js": () => import("./../../../src/templates/news-detail.js" /* webpackChunkName: "component---src-templates-news-detail-js" */),
  "component---src-templates-project-detail-js": () => import("./../../../src/templates/project-detail.js" /* webpackChunkName: "component---src-templates-project-detail-js" */)
}

