import React, { useState, useEffect, useContext } from 'react'
import * as classes from './Preloader.module.scss'
import IconLogoMini from './IconLogoMini'
import IconLogoBg from './IconLogoBg'
import anime from 'animejs'
import { FormContext } from '../../../context'
import { Player } from '@lottiefiles/react-lottie-player'
import jsonData from '../../../animation/preloader.json'

function Preloader() {
    const { setAnimationStart, setIsVisibilityPreloader } = useContext(FormContext)
    const [count, setCount] = useState(0)

    useEffect(() => {
        const wrap = document.querySelector(`.${classes.Wrap}`)
        const icon = document.querySelector(`#preloader-logo-bg`)
        const logo = document.querySelector(`.${classes.Logo}`)

        if (wrap) {
            anime({
                targets: wrap,
                duration: 300,
                delay: 0,
                easing: 'cubicBezier(.25, .1, .25, 1)',
                backgroundColor: ['#000000', '#081c2e']
            })
        }

        if (icon) {
            anime({
                targets: icon,
                duration: 1000,
                delay: 300,
                easing: 'cubicBezier(.25, .1, .25, 1)',
                opacity: [0, 1]
            })
        }

        if (logo) {
            anime({
                targets: logo,
                translateY: [50, 0],
                opacity: [0, 1],
                easing: 'cubicBezier(0, 0, 0.03, 1.01)',
                duration: 1500,
                delay: 1150
            })
        }
    }, [])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCount((prev) => prev + 1)
        }, 25)

        if (count >= 100) {
            clearTimeout(timeout)

            setTimeout(() => {
                setAnimationStart(true)

                const wrap = document.querySelector(`.${classes.Wrap}`)

                if (wrap) {
                    anime({
                        targets: wrap,
                        duration: 750,
                        delay: 0,
                        easing: 'cubicBezier(.25, .1, .25, 1)',
                        opacity: [1, 0]
                    }).finished.then(() => setIsVisibilityPreloader(false))
                } else {
                    setIsVisibilityPreloader(false)
                }
            }, 200)
        }

        return () => clearTimeout(timeout)
    }, [count])

    return (
        <div className={classes.Preloader}>
            <div className={classes.Wrap}>
                <IconLogoBg className={classes.Icon} />

                <div className={classes.LogoAnimate}>
                    <Player autoplay={true} loop={false} src={jsonData} speed={1} renderer='svg' keepLastFrame={true} />
                </div>

                <div className={classes.Logo}>
                    <IconLogoMini />
                </div>

                <p className={classes.Count}>{count}%</p>
                <div className={classes.Progress} style={{ width: `${count}%` }} />
            </div>
        </div>
    )
}

export default Preloader
