import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Transition from '../components/help/Transition/Transition'
import { FormProvider } from '../context'
import { Helmet } from 'react-helmet'

const Layout = ({ children, location }) => {
    useEffect(() => {
        if (location.pathname === '/' || location.pathname === '/en/') {
            document.getElementsByTagName( 'html' )[0].classList.add("html-page-main")
            document.body.classList.add("page-main")
        } else {
            document.getElementsByTagName( 'html' )[0].classList.remove("html-page-main")
            document.body.classList.remove("page-main")
        }
    }, [location.pathname])

    return (
        <FormProvider>
            <Helmet>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ed1c21" />
                <meta name="msapplication-TileColor" content="#0c1f2f" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>

            <Transition location={location}>{children}</Transition>
        </FormProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout