import React from 'react'

function IconLogoMini({ className }) {
    return (
        <svg
            className={className}
            id='preloader-logo-mini'
            width={171}
            height={44}
            viewBox='0 0 171 44'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M82.989 7.88484C82.989 2.49465 86.7645 0.847656 91.6329 0.847656H96.1039C100.898 0.847656 105.071 2.39484 105.071 7.88484V8.2342H101.693V7.88484C101.693 4.3912 98.2649 3.89211 95.4581 3.89211H91.6329C88.6026 3.89211 86.2677 4.3912 86.2677 7.88484C86.2677 11.3785 89.8445 11.2038 92.4774 11.2038H95.9052C100.699 11.2038 105.667 12.202 105.667 18.241C105.667 23.8557 101.941 25.3031 96.9981 25.3031H91.1361C86.1683 25.3031 82.4922 23.0821 82.4922 17.8167V17.4674H85.7709V17.8167C85.7709 21.6597 88.6522 22.3585 91.9807 22.3585H96.7497C99.5565 22.3585 102.338 21.6597 102.338 18.3158C102.338 14.9719 99.5069 14.4229 96.551 14.4229H91.5832C87.0626 14.4229 82.9393 13.4747 82.9393 8.00961'
                fill='white'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M29.7115 0.898438H28.5938V4.29226V11.0799V14.4987V21.8353V25.304H31.8476H32.096H51.2469V21.8353H32.096V14.4987H50.452V11.0799H32.096V4.29226H51.2469V0.898438H29.7115Z'
                fill='white'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M129.188 25.304H132.69V0.898438H129.188V10.9801H112.769V0.898438H109.242V25.304H112.769V14.4488H129.188V25.304Z'
                fill='white'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M55.6641 0L73.2252 16.0957L75.0633 17.7926L75.6097 18.2917L79.1369 21.5358V26.2023L59.2657 7.93554V25.4037H55.6641V0ZM75.5352 14.3988V0.848455H79.0623V17.5929L75.5352 14.3988Z'
                fill='white'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.57681 14.5783V25.3088H0V0.803412H14.5805C17.1049 0.64405 19.6087 1.34767 21.6844 2.79977C22.8721 3.77721 23.6347 5.18054 23.8109 6.71281C23.987 8.24509 23.5629 9.78611 22.6283 11.0098C22.1101 11.6293 21.488 12.1529 20.7902 12.557C23.2741 13.7049 23.4728 17.0488 23.4728 19.3446V25.3088H20.0947V19.0951C20.1759 17.7759 19.8831 16.4607 19.2502 15.302C18.4347 14.7434 17.4511 14.4875 16.4682 14.5783H3.57681ZM3.57681 4.07246V11.3841H15.3753C16.6943 11.4891 18.0093 11.1368 19.1011 10.386C19.5124 10.0769 19.8462 9.67578 20.0762 9.21444C20.3062 8.7531 20.4259 8.24426 20.4259 7.7283C20.4259 7.21235 20.3062 6.70349 20.0762 6.24215C19.8462 5.78081 19.5124 5.37969 19.1011 5.07064C18.0403 4.32925 16.7654 3.96079 15.4747 4.02255H3.57681V4.07246Z'
                fill='white'
            />
            <path d='M141.336 0.898438H137.809V25.304H141.336V0.898438Z' fill='white' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M146.406 0L163.967 16.0957L165.781 17.7926L166.327 18.2917L169.854 21.5358V26.2023L149.983 7.93554V25.4037H146.406V0ZM166.277 14.3988V0.848455H169.805V17.5929L166.277 14.3988Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M6.31171 34.1875H7.40463V43.5455H6.31171V42.0232C6.06209 42.5434 5.66456 42.9775 5.16937 43.2706C4.67419 43.5638 4.1036 43.7028 3.52975 43.6702C3.09837 43.6813 2.66973 43.5982 2.27346 43.4266C1.87718 43.255 1.52272 42.999 1.23457 42.6763C0.946419 42.3536 0.73145 41.9718 0.604519 41.5575C0.477588 41.1431 0.441724 40.706 0.499403 40.2764V34.1875H1.59231V40.2015C1.56075 40.5202 1.59662 40.8419 1.69758 41.1457C1.79853 41.4494 1.96229 41.7283 2.17809 41.9639C2.3939 42.1996 2.65687 42.3867 2.94975 42.5131C3.24263 42.6394 3.5588 42.7021 3.8775 42.697C4.21662 42.6976 4.55227 42.6285 4.86377 42.4938C5.17527 42.3591 5.45603 42.1618 5.68874 41.914C5.92145 41.6662 6.10119 41.3731 6.21687 41.0528C6.33255 40.7326 6.38174 40.3919 6.36139 40.0518L6.31171 34.1875Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M11.6523 34.1889H12.6707V35.6362C12.8868 35.1573 13.2364 34.7515 13.6773 34.4681C14.1181 34.1846 14.6313 34.0356 15.1546 34.0391H15.7011V35.1371H15.0553C14.3965 35.1371 13.7647 35.4 13.2989 35.868C12.8331 36.336 12.5714 36.9707 12.5714 37.6326V43.6716H11.6523V34.1889Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M23.1762 42.7475C25.0143 42.7475 26.0576 41.0506 26.0576 38.9045C26.0576 36.7584 25.064 35.0865 23.1762 35.0865C21.2885 35.0865 20.3446 36.7085 20.3446 38.9045C20.3446 41.1005 21.3878 42.7475 23.1762 42.7475ZM19.2517 31.0938H20.3446V36.0847C20.5508 35.5028 20.9368 35.0024 21.4463 34.6567C21.9558 34.311 22.562 34.138 23.1762 34.1632C25.4614 34.1632 27.0014 36.1346 27.0014 39.1541C27.0014 42.1736 25.4614 43.9953 23.1762 43.9953C22.5581 44.0227 21.9478 43.8477 21.4371 43.4967C20.9265 43.1457 20.5431 42.6376 20.3446 42.0488H20.1956V43.7956H19.1523L19.2517 31.0938Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M36.4965 40.0036V38.656C35.7163 39.0127 34.8698 39.1998 34.0126 39.205C32.4229 39.4046 31.5287 39.8538 31.5287 41.0017C31.5287 42.1496 32.4229 42.7985 33.6649 42.7985C34.038 42.8196 34.4114 42.7622 34.7612 42.6299C35.1109 42.4976 35.4293 42.2933 35.6959 42.0302C35.9625 41.767 36.1714 41.4508 36.3093 41.1018C36.4472 40.7528 36.5109 40.3787 36.4965 40.0036ZM30.5352 40.9518C30.5352 39.1052 32.0255 38.4564 34.112 38.2567C35.652 38.0571 36.5959 38.0571 36.5959 37.3584V37.1587C36.6168 36.8704 36.5735 36.5811 36.4692 36.3117C36.3649 36.0423 36.2022 35.7996 35.9929 35.6012C35.7836 35.4029 35.5329 35.2539 35.2591 35.165C34.9853 35.0762 34.6953 35.0497 34.41 35.0875C33.8929 35.0679 33.3815 35.2019 32.9398 35.4726C32.498 35.7433 32.1454 36.1389 31.9261 36.6097L30.8829 36.2604C31.1794 35.5831 31.6781 35.0151 32.31 34.6351C32.9419 34.2551 33.6758 34.0818 34.41 34.1392C34.8426 34.0858 35.2816 34.1299 35.6951 34.2684C36.1086 34.4069 36.4861 34.6363 36.8002 34.9399C37.1143 35.2434 37.357 35.6136 37.5107 36.0233C37.6645 36.4331 37.7253 36.8721 37.6888 37.3085V43.6469H36.4965V42.1496C36.232 42.6829 35.8187 43.1273 35.3071 43.4287C34.7954 43.73 34.2076 43.8752 33.6152 43.8466C33.2236 43.8706 32.8313 43.8133 32.4627 43.6783C32.0941 43.5433 31.7572 43.3334 31.4729 43.0618C31.1885 42.7903 30.963 42.4628 30.8102 42.0997C30.6574 41.7367 30.5807 41.3459 30.5848 40.9518'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M42.7726 43.5429H41.6797V34.1849H42.7229V35.6572C42.9634 35.1477 43.3491 34.7215 43.8312 34.4327C44.3133 34.1439 44.8699 34.0055 45.4304 34.0352C45.8711 34.0129 46.3114 34.0884 46.7198 34.2563C47.1283 34.4243 47.4949 34.6805 47.7936 35.0068C48.0924 35.3332 48.3159 35.7216 48.4484 36.1446C48.5809 36.5675 48.619 37.0145 48.5601 37.454V43.493H47.5168V37.5039C47.5447 37.1839 47.5054 36.8616 47.4015 36.5579C47.2976 36.2542 47.1314 35.9757 46.9137 35.7405C46.696 35.5053 46.4316 35.3186 46.1376 35.1925C45.8436 35.0664 45.5265 35.0037 45.2068 35.0084C44.8655 35.0076 44.5276 35.0774 44.2143 35.2136C43.901 35.3498 43.6191 35.5494 43.386 35.7999C43.1529 36.0505 42.9737 36.3466 42.8597 36.6699C42.7456 36.9931 42.699 37.3364 42.7229 37.6785L42.7726 43.5429Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M62.5218 34.1912H63.5899V43.5491H62.5218V34.1912ZM62.2734 31.8454C62.2734 31.6875 62.3201 31.5331 62.4074 31.4018C62.4947 31.2705 62.6189 31.1681 62.7641 31.1077C62.9093 31.0472 63.0692 31.0314 63.2233 31.0622C63.3775 31.093 63.5192 31.1691 63.6303 31.2808C63.7415 31.3924 63.8172 31.5347 63.8479 31.6896C63.8785 31.8445 63.8628 32.0051 63.8026 32.151C63.7425 32.2969 63.6406 32.4216 63.5099 32.5094C63.3792 32.5971 63.2255 32.644 63.0683 32.644C62.8613 32.6321 62.666 32.5441 62.5194 32.3968C62.3728 32.2496 62.2853 32.0533 62.2734 31.8454Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M68.9054 43.5443H67.8125V34.1863H68.7812V35.6586C69.0233 35.145 69.4129 34.716 69.8999 34.4269C70.3869 34.1378 70.9489 34.0018 71.5135 34.0366C71.9543 34.0143 72.3945 34.0898 72.803 34.2577C73.2114 34.4256 73.578 34.6819 73.8768 35.0082C74.1755 35.3346 74.3991 35.723 74.5315 36.1459C74.664 36.5689 74.7021 37.0159 74.6432 37.4553V43.4943H73.749V37.5052C73.7771 37.1832 73.7371 36.8587 73.6318 36.5532C73.5264 36.2477 73.358 35.968 73.1376 35.7324C72.9172 35.4969 72.6497 35.3107 72.3527 35.1862C72.0557 35.0616 71.7359 35.0015 71.4141 35.0098C71.0728 35.009 70.7349 35.0788 70.4216 35.215C70.1084 35.3512 69.8264 35.5508 69.5933 35.8013C69.3602 36.0519 69.1811 36.348 69.067 36.6712C68.9529 36.9945 68.9064 37.3378 68.9303 37.6799V43.5692L68.9054 43.5443Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M82.4959 43.5478H81.3533L78 34.2148H79.1923L81.9494 42.1005H82.0487L84.8307 34.2148H85.9733L82.4959 43.5478Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M95.4606 38.2531C95.491 37.8537 95.4412 37.4522 95.3144 37.0725C95.1875 36.6927 94.9861 36.3424 94.7221 36.0422C94.4581 35.7421 94.1368 35.4982 93.7773 35.325C93.4177 35.1518 93.0273 35.0529 92.629 35.034C91.8142 35.079 91.0485 35.4396 90.4927 36.0398C89.9369 36.6401 89.6342 37.4334 89.6483 38.2531H95.4606ZM88.6548 38.9518C88.5596 38.3496 88.5924 37.734 88.7512 37.1454C88.9099 36.5569 89.191 36.0088 89.5759 35.5373C89.9607 35.0658 90.4406 34.6815 90.984 34.4098C91.5274 34.1381 92.1219 33.9851 92.7283 33.9609C94.7651 33.9609 96.6281 35.4083 96.6281 38.5775V39.1265H89.6483C89.5997 39.5702 89.6442 40.0192 89.7789 40.4446C89.9136 40.8699 90.1356 41.2622 90.4305 41.5961C90.7253 41.9299 91.0866 42.198 91.4909 42.383C91.8953 42.568 92.3337 42.6658 92.778 42.6701C93.2979 42.6875 93.8112 42.5499 94.2533 42.2745C94.6953 41.9992 95.0463 41.5984 95.2619 41.1229L96.2803 41.4723C95.991 42.1549 95.4999 42.7316 94.8734 43.1244C94.247 43.5172 93.5155 43.7071 92.778 43.6683C92.1958 43.6401 91.6251 43.4945 91.1 43.2402C90.5748 42.986 90.106 42.6282 89.7213 42.1881C89.3366 41.7481 89.0439 41.2348 88.8607 40.6789C88.6775 40.1229 88.6074 39.5356 88.6548 38.9518Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M105.523 36.5607C105.402 36.0951 105.121 35.6873 104.73 35.4089C104.34 35.1305 103.864 34.9993 103.387 35.0384C102.145 35.0384 101.25 35.6872 101.25 36.6605C101.25 37.6337 101.797 37.9581 102.939 38.2077L104.33 38.557C105.87 38.9563 106.665 39.7548 106.665 41.0525C106.665 42.3501 105.274 43.7476 103.237 43.7476C102.465 43.837 101.687 43.6294 101.061 43.1665C100.434 42.7036 100.005 42.0197 99.8594 41.2521L100.903 41.0026C101.011 41.5272 101.31 41.9921 101.742 42.3061C102.174 42.6201 102.708 42.7604 103.237 42.6995C104.678 42.6995 105.572 42.0007 105.572 41.0026C105.572 40.0044 105.076 39.7049 104.082 39.5053L102.542 39.1559C100.903 38.7567 100.058 37.9082 100.058 36.6605C100.058 35.4127 101.399 34.165 103.287 34.165C103.985 34.1115 104.679 34.3041 105.25 34.7095C105.821 35.1149 106.234 35.7078 106.417 36.386L105.523 36.5607Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M114.064 35.1386H112.077V41.1028C112.077 42.3006 112.772 42.6 113.468 42.6C113.681 42.6363 113.9 42.6363 114.113 42.6L114.362 43.5982C114.062 43.7175 113.74 43.7686 113.418 43.7479C113.097 43.7762 112.774 43.736 112.47 43.6302C112.166 43.5243 111.888 43.3551 111.653 43.1337C111.419 42.9122 111.233 42.6435 111.11 42.3451C110.986 42.0468 110.926 41.7254 110.934 41.4022V35.0887H109.543V34.1404H111.008V31.8945H112.101V34.1404H114.088V35.1386H114.064Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M117.871 34.1856H118.914V35.633C119.112 35.1444 119.46 34.7315 119.906 34.4535C120.352 34.1755 120.875 34.0468 121.398 34.0858C121.94 34.0574 122.476 34.2079 122.924 34.5143C123.373 34.8207 123.709 35.2662 123.882 35.7827V35.7827C124.101 35.2462 124.483 34.7931 124.974 34.4882C125.465 34.1834 126.039 34.0425 126.614 34.0858C128.353 34.0858 129.396 35.1838 129.396 37.3049V43.5436H128.303V37.3049C128.345 37.0187 128.322 36.7268 128.236 36.4505C128.151 36.1742 128.006 35.9205 127.811 35.7078C127.616 35.4951 127.376 35.3288 127.109 35.2208C126.842 35.1129 126.554 35.0662 126.267 35.084C125.949 35.0737 125.632 35.1333 125.339 35.2587C125.047 35.3841 124.785 35.5721 124.572 35.8098C124.359 36.0474 124.2 36.3289 124.107 36.6346C124.013 36.9402 123.988 37.2627 124.031 37.5794V43.7182H123.187V37.255C123.216 36.98 123.185 36.702 123.097 36.4399C123.009 36.1778 122.866 35.9379 122.677 35.7365C122.489 35.5352 122.259 35.3771 122.004 35.2731C121.749 35.169 121.474 35.1216 121.2 35.1339C120.887 35.1431 120.58 35.2158 120.297 35.3477C120.013 35.4796 119.759 35.6679 119.55 35.9011C119.341 36.1344 119.182 36.4078 119.081 36.7049C118.98 37.0019 118.94 37.3164 118.964 37.6293V43.6184H117.871V34.1856Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M140.25 38.2531C140.28 37.8537 140.231 37.4522 140.104 37.0725C139.977 36.6927 139.776 36.3424 139.511 36.0422C139.247 35.7421 138.926 35.4982 138.567 35.325C138.207 35.1518 137.817 35.0529 137.418 35.034C136.604 35.079 135.838 35.4396 135.282 36.0398C134.726 36.6401 134.424 37.4334 134.438 38.2531H140.25ZM133.37 38.9518C133.274 38.3496 133.307 37.734 133.466 37.1454C133.625 36.5569 133.906 36.0088 134.291 35.5373C134.676 35.0658 135.155 34.6815 135.699 34.4098C136.242 34.1381 136.837 33.9851 137.443 33.9609C139.48 33.9609 141.368 35.4083 141.368 38.5775V39.1265H134.438C134.389 39.5702 134.434 40.0192 134.568 40.4446C134.703 40.8699 134.925 41.2622 135.22 41.596C135.515 41.9299 135.876 42.198 136.28 42.383C136.685 42.568 137.123 42.6658 137.567 42.6701C138.087 42.6875 138.601 42.5499 139.043 42.2745C139.485 41.9992 139.836 41.5984 140.051 41.1229L141.095 41.4723C140.798 42.1547 140.302 42.7303 139.672 43.1225C139.042 43.5148 138.308 43.7051 137.567 43.6683C136.979 43.6499 136.4 43.5115 135.866 43.2616C135.332 43.0116 134.855 42.6553 134.462 42.2142C134.069 41.7732 133.77 41.2565 133.582 40.6957C133.395 40.1349 133.322 39.5416 133.37 38.9518'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M146.159 43.5429H145.066V34.1849H146.11V35.6572C146.35 35.1477 146.736 34.7215 147.218 34.4327C147.7 34.1439 148.257 34.0055 148.817 34.0352C149.258 34.0129 149.698 34.0884 150.107 34.2563C150.515 34.4243 150.882 34.6805 151.18 35.0068C151.479 35.3332 151.703 35.7216 151.835 36.1446C151.968 36.5675 152.006 37.0145 151.947 37.454V43.493H150.854V37.5039C150.882 37.1839 150.842 36.8616 150.739 36.5579C150.635 36.2542 150.468 35.9757 150.251 35.7405C150.033 35.5053 149.769 35.3186 149.475 35.1925C149.181 35.0664 148.864 35.0037 148.544 35.0084C148.203 35.0076 147.865 35.0774 147.551 35.2136C147.238 35.3498 146.956 35.5494 146.723 35.7999C146.49 36.0505 146.311 36.3466 146.197 36.6699C146.083 36.9931 146.036 37.3364 146.06 37.6785V43.5678L146.159 43.5429Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M159.989 35.1386H158.002V41.1028C158.002 42.3006 158.698 42.6 159.393 42.6C159.607 42.6363 159.825 42.6363 160.039 42.6L160.287 43.5982C159.988 43.7175 159.665 43.7686 159.344 43.7479C159.023 43.7762 158.7 43.736 158.396 43.6302C158.092 43.5243 157.814 43.3551 157.579 43.1337C157.345 42.9122 157.159 42.6435 157.035 42.3451C156.911 42.0468 156.851 41.7254 156.86 41.4022V35.0887H155.469V34.1404H156.86V31.8945H157.953V34.1404H159.94V35.1386H159.989Z'
                fill='white'
            />
            <path
                opacity='0.4'
                d='M168.861 36.5607C168.74 36.0951 168.46 35.6873 168.069 35.4089C167.678 35.1305 167.202 34.9993 166.725 35.0384C165.483 35.0384 164.589 35.6872 164.589 36.6605C164.589 37.6337 165.135 37.9581 166.278 38.2077L167.669 38.557C169.209 38.9563 170.004 39.7548 170.004 41.0525C170.004 42.3501 168.613 43.7476 166.576 43.7476C165.806 43.8363 165.032 43.6279 164.409 43.1646C163.787 42.7013 163.362 42.0177 163.223 41.2521L164.266 41.0026C164.369 41.5251 164.663 41.9898 165.091 42.3042C165.519 42.6186 166.049 42.7597 166.576 42.6995C168.017 42.6995 168.911 42.0007 168.911 41.0026C168.911 40.0044 168.414 39.7049 167.42 39.5053L165.88 39.1559C164.266 38.7567 163.397 37.9082 163.397 36.6605C163.397 35.4127 164.713 34.165 166.601 34.165C167.298 34.1115 167.992 34.3041 168.564 34.7095C169.135 35.1149 169.548 35.7078 169.73 36.386L168.861 36.5607Z'
                fill='white'
            />
        </svg>
    )
}

export default IconLogoMini
