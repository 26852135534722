import React, { useContext, useEffect } from 'react'
import * as classes from './PageLoader.module.scss'
import IconLogoBg from '../../ui/Preloader/IconLogoBg'
import jsonData from '../../../animation/page-transition-v2.json'
import { Player } from '@lottiefiles/react-lottie-player'
import anime from 'animejs'
import { FormContext } from '../../../context'
import IconLogoMini from '../../ui/Preloader/IconLogoMini'

export default function PageLoader() {
    const { setPageLoader, setAnimationStart } = useContext(FormContext)

    useEffect(() => {
        const wrap = document.querySelector(`.${classes.Wrap}`)
        const logo = document.querySelector(`.${classes.Logo}`)

        if (wrap) {
            anime({
                targets: wrap,
                duration: 300,
                delay: 0,
                easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
                opacity: [0, 1]
            })
        }

        if (logo) {
            anime({
                targets: logo,
                duration: 1000,
                delay: 500,
                easing: 'cubicBezier(0, 0, 0.03, 1.01)',
                opacity: [0, 1],
                translateY: [50, 0]
            })
        }

        setTimeout(() => {
            setAnimationStart(true)

            if (wrap) {
                const animation = anime({
                    targets: wrap,
                    duration: 750,
                    delay: 0,
                    easing: 'cubicBezier(.25, .1, .25, 1)',
                    opacity: [1, 0]
                })

                animation.finished.then(() => setPageLoader(false))
            } else {
                setPageLoader(false)
            }
        }, 1600)
    }, [])

    return (
        <div className={classes.PageLoader}>
            <div className={classes.Wrap}>
                <IconLogoBg className={classes.Icon} />

                <div className={classes.LogoAnimate}>
                    <Player autoplay={true} loop={false} src={jsonData} speed={1} renderer='svg' keepLastFrame={true} />
                </div>

                <div className={classes.Logo}>
                    <IconLogoMini />
                </div>
            </div>
        </div>
    )
}
