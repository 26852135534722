import React, { createContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'

const defaultState = {
    showModal: null,
    onOpenModal: () => {},
    onCloseModal: () => {},
    isVisibilityPreloader: true,
    pageLoader: false,
    animationStart: false,
    onHiddenPreloader: () => {},
    popupCookie: false
}

export const FormContext = createContext(defaultState)

function FormProvider({ children }) {
    // TYPES FORM: 'menu'
    const [showModal, setShowModal] = useState(null)

    const [isVisibilityPreloader, setIsVisibilityPreloader] = useState(true)
    const [pageLoader, setPageLoader] = useState(false)
    const [animationStart, setAnimationStart] = useState(false)
    const [popupCookie, setPopupCookie] = useState(false)

    const onHiddenPreloader = () => {
        setIsVisibilityPreloader(false)
    }

    const onOpenModal = (nameForm) => {
        const body = document.querySelector('body')
        body.classList.add('no-scroll')

        setShowModal(nameForm)
    }

    const onCloseModal = () => {
        const body = document.querySelector('body')
        body.classList.remove('no-scroll')

        setShowModal(null)
    }

    const value = {
        showModal,
        onOpenModal,
        onCloseModal,
        isVisibilityPreloader,
        setIsVisibilityPreloader,
        onHiddenPreloader,
        pageLoader,
        setPageLoader,
        animationStart,
        setAnimationStart,
        popupCookie,
        setPopupCookie
    }

    useEffect(() => {
        if (Cookies.get('cookie-close') !== 'Y') {
            setPopupCookie(true)
        }
    }, [])

    return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}

export { FormProvider }
