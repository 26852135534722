import React, { useContext, useEffect, useRef } from 'react'
import * as classes from "./Transition.module.scss"
import { TransitionGroup, Transition as ReactTransition, CSSTransition } from 'react-transition-group'
import { FormContext } from '../../../context'
import PageLoader from '../PageLoader/PageLoader'
import Preloader from '../../ui/Preloader/Preloader'
import anime from 'animejs'

const getTransitionStyles = {
    entering: {
        display: 'none',
    },
    entered: {
        display: 'block',
    },
    exiting: {
        display: 'block',
    },
    exited: {
        display: 'none',
    },
}

export default function Transition({children, location}) {
    const { pageLoader, setPageLoader, isVisibilityPreloader, animationStart } = useContext(FormContext)
    const container = useRef(null)

    useEffect(() => {
        if (!pageLoader && !isVisibilityPreloader) {
            setPageLoader(true)
        }
    }, [location.pathname])

    useEffect(() => {
        if (animationStart) {
            container.current.style.opacity = '0';

            anime({
                targets: container.current,
                duration: 750,
                delay: 0,
                easing: 'cubicBezier(.25, .1, .25, 1)',
                opacity: [0, 1],
            })
        }
    }, [animationStart])

    return (
        <>
            <CSSTransition in={isVisibilityPreloader} timeout={1} mountOnEnter={true} unmountOnExit={true}>
                {state => <Preloader className={state} />}
            </CSSTransition>

            <div
                className={classes.Transition}
                ref={container}
            >
                <TransitionGroup>
                    <ReactTransition
                        key={location.pathname}
                        timeout={{
                            enter: 350,
                            exit: 350,
                        }}
                    >
                        {status => (
                            <div
                                style={{
                                    ...getTransitionStyles[status],
                                }}
                            >
                                {children}
                            </div>
                        )}
                    </ReactTransition>
                </TransitionGroup>
            </div>

            <CSSTransition in={pageLoader} timeout={1} mountOnEnter={true} unmountOnExit={true}>
                {state => <PageLoader className={state} />}
            </CSSTransition>
        </>
    )
}