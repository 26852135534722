import React from 'react'

function IconLogoBg({ className }) {
    return (
        <svg
            id='preloader-logo-bg'
            className={className}
            width={1000}
            height={968}
            viewBox='0 0 1000 968'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g opacity='0.02'>
                <path
                    d='M771.409 343.554L508.779 193.254L232.64 345.838L170.711 380.009L231.5 415.321L293.429 381.151L506.586 263.353L709.48 379.394L770.357 414.267L832.286 378.34L771.409 343.554Z'
                    fill='white'
                />
                <path
                    d='M508.769 0L0 281.098L170.701 380.01L178.508 384.49L231.49 415.323L239.297 419.803L364.823 492.625L372.542 497.105L435.524 533.56L443.243 538.04L499.997 570.981L562.979 534.438L570.611 530.046L634.909 492.712L642.54 488.32L762.715 418.661L770.347 414.269L824.645 382.82L832.276 378.341L999.995 281.098L508.769 0ZM771.399 343.555L763.768 347.947L709.47 379.395L701.838 383.875L581.663 453.535L574.032 457.927L509.734 495.26L499.997 500.882L497.366 499.389L434.472 462.846L426.752 458.366L301.139 385.632L293.42 381.152L240.35 350.319L232.63 345.839L122.806 282.152L506.576 70.0987L877.188 282.152L771.399 343.555Z'
                    fill='white'
                />
                <path
                    d='M939.03 448.266L877.188 484.194L871.311 487.62L771.399 545.596L709.47 581.436L581.663 655.576L574.032 659.968L509.734 697.301L499.997 702.923L497.366 701.43L434.471 664.887L426.752 660.407L293.419 583.193L232.63 547.88L166.052 509.317L122.806 484.194L61.9295 448.881L0 483.14L170.701 582.051L231.49 617.364L364.823 694.666L372.542 699.146L435.524 735.601L443.243 740.081L499.997 773.022L562.979 736.479L570.611 732.087L634.909 694.754L642.54 690.361L770.347 616.31L832.276 580.382L999.994 483.14L939.03 448.266Z'
                    fill='white'
                />
                <path
                    d='M939.03 642.645L877.188 678.573L871.311 681.999L771.399 739.975L709.47 775.815L581.663 849.955L574.032 854.347L509.734 891.68L499.997 897.302L497.366 895.809L434.471 859.266L426.752 854.786L293.419 777.572L232.63 742.259L166.052 703.696L122.806 678.573L61.9295 643.259L0 677.519L170.701 776.43L231.49 811.743L364.823 889.045L372.542 893.525L435.524 929.98L443.243 934.46L499.997 967.401L562.979 930.858L570.611 926.466L634.909 889.132L642.54 884.74L770.347 810.689L832.276 774.761L999.994 677.519L939.03 642.645Z'
                    fill='white'
                />
                <path d='M410.168 448.758L500.001 395.293L592.07 447.499L500.001 500.88L410.168 448.758Z' fill='white' />
                <path d='M508.775 0L506.582 70.0987L877.194 282.152L1000 281.098L508.775 0Z' fill='#989898' />
                <path
                    d='M500.001 500.883V570.983C500.001 570.983 -1.68124 280.123 0.00423749 281.1C1.68975 282.077 122.811 282.154 122.811 282.154L500.001 500.883Z'
                    fill='#989898'
                />
                <path d='M508.775 193.254L506.582 263.353L709.475 379.394L771.405 343.554L508.775 193.254Z' fill='#989898' />
                <path d='M500.004 395.293V502.336L592.072 447.499L500.004 395.293Z' fill='#989898' />
                <path d='M499.997 773.021V702.921L61.9295 448.879L0 483.138L499.997 773.021Z' fill='#989898' />
                <path d='M499.997 967.095V896.996L61.9295 642.953L0 677.212L499.997 967.095Z' fill='#989898' />
            </g>
        </svg>
    )
}

export default IconLogoBg
